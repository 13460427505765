import React, { memo, useEffect } from 'react'
import useApi from 'hooks/useApi'
import { statusConfirmed } from 'constants/bookings'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import Gateways from './gateways'
import { getBooking } from 'actions/booking'
import { navigate } from 'gatsby'
import { paymentGateways } from 'config/app'

const PaymentBooking = memo(({ bookingId, location: { search }, ...props }) => {
  const [booking, gateways] = useApi(() => Promise.all([getBooking(bookingId), getAllPaymentGateways()]))

  useEffect(() => {
    if (booking && (booking.status === statusConfirmed.value || !booking.fee)) {
      navigate(`/booking/${bookingId}/confirmation${search ?? ''}`)
    }
  }, [booking])

  return (
    <>
      {booking?.status === statusConfirmed.value || !booking?.fee ? (
        <></>
      ) : (
        <Gateways
          gateways={paymentGateways.filter((pg) => gateways?.data.find((g) => g.id === pg))}
          bookingId={bookingId}
          search={search}
          {...props}
        />
      )}
    </>
  )
})

export default PaymentBooking
