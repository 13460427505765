import React, { lazy, memo, useState, useEffect } from 'react'
import Suspense from 'components/Public/Suspense'
import Loading from './gateways/loading'

const Gateway = memo(({ gateway, onClick, autoSelect, ...props }) => {
  const [loading, setLoading] = useState(false)
  const Gateway = lazy(() => import(`./gateways/${gateway}`))

  useEffect(() => {
    if (autoSelect) {
      setTimeout(onSelect, 1000)
    }
  }, [autoSelect])

  const onSelect = () => {
    setLoading(true)
    onClick(gateway)
  }

  return <Suspense fallback={<Loading />}>{loading ? <Loading /> : <Gateway {...props} onClick={onSelect} />}</Suspense>
})

export default Gateway
