import React, { memo, useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import { paymentGateways } from 'config/app'
import Gateway from './gateway'
import { payBooking } from 'actions/booking'
import { useLoading } from 'hooks/useLoading'
import Form from './Form'

const PaymentBooking = memo(({ bookingId, search, auto = true }) => {
  const [gateways] = useApi(() => Promise.all([getAllPaymentGateways()]))
  const [, setLoading] = useLoading()
  const [form, setForm] = useState()

  const onSelect = (gateway) => async () => {
    setLoading(true)
    const res = await payBooking(bookingId, new URLSearchParams(search).toString(), gateway)
    if (res.data?.form) {
      setForm(res.data.form)
    } else {
      window.location = res.data?.url
    }
  }

  useEffect(() => {
    if (auto && gateways && gateways?.data.length === 1) {
      onSelect(gateways?.data.shift().id)()
    }
  }, [gateways])

  return (
    <>
      {paymentGateways
        .filter((pg) => gateways?.data.find((g) => g.id === pg))
        .map((pg) => (
          <Gateway key={pg} gateway={pg} config={gateways?.data.find((g) => g.id === pg)} onClick={onSelect(pg)} />
        ))}
      {form && <Form form={form} />}
    </>
  )
})

export default PaymentBooking
